<template>
  <v-container id="dashboard" class="pa-4 pa-sm-8">
    <v-row>
      <v-col cols="12">
        <v-card id="title">
          <div class="titleimg px-8">
            <v-img src="@/assets/logo2022.svg" width="40vw" max-width="200" v-if="$vuetify.breakpoint.smAndDown" contain/>
            <v-img src="@/assets/title_logo.svg" width="40vw" max-width="350" v-else contain/>
            <div class="point-remaind" title="残りのポイント数" v-if="user_plan.plan_mst.contract_model == 'point'">
              <v-img src="@/assets/ico/ico_point.svg" width="22" class="d-inline-block" />残りポイント数は  <strong class="text-h6">{{ user_plan.remainder_point }}</strong><span class="unit">Pt</span>です</div>
          </div>
          <v-card-title v-if="$vuetify.breakpoint.smAndUp" class="">{{ loginUserInfo.name }}さん、トコトンへようこそ。</v-card-title>
          <v-divider />
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-btn text block x-large @click="$emit('addNewRequest')"><v-icon size="28" color="secondary" left>mdi-checkbox-marked-circle-plus-outline</v-icon>新しく依頼を作成</v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn text block x-large :to="{ name: 'client:request_index', params: { fillter: 'all' } }"><v-icon color="secondary" size="28" left>mdi-format-list-bulleted-square</v-icon>依頼一覧</v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn text block x-large href="#myTask"><v-icon size="28" color="secondary" left>mdi-account-check</v-icon>自分のタスク</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- 最新のコメント -->
      <v-col cols="12" sm="6">
        <v-card class="with-counts" height="100%">
          <v-card-title>最新のコメント</v-card-title>
          <v-card-text class="pb-0">
            <v-alert v-show="!newComments.length" type="info" dense text color="grey">新しいコメントはありません</v-alert>
            <v-list-item v-for="(item, i) in newComments.slice(0, 10)" :key="`first-${i}`" class="px-0" :to="{ name: 'client:request_show', params: { id: item.request.id } }">
                <v-list-item-avatar width="32" height="32" min-width="32" class="overflow-visible mr-2">
                  <LoginAvatar :user="item.submit_login" :size="32" />
                </v-list-item-avatar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ item.comment_title }}
                    </v-list-item-title>
                  </template>
                  <span>【{{ item.request.submit_login.end_user.end_company_name }}】【#{{ item.request.id }}】{{ item.request.subject }}</span>
                </v-tooltip>
                <v-list-item-action class="ml-0">
                    <span><v-chip x-small outlined>{{ item.create_at | moment("from") }}</v-chip></span>
                </v-list-item-action>
            </v-list-item>
            <!-- more -->
            <v-list-item v-for="(item, i) in newComments.slice(11)" :key="`more-${i}`" class="px-0" :to="{ name: 'client:request_show', params: { id: item.request.id } }" v-show="isCommentsMore">
                <v-list-item-avatar width="32" height="32" min-width="32" class="overflow-visible mr-2">
                  <LoginAvatar :user="item.submit_login" :size="32" />
                </v-list-item-avatar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ item.comment_title }}
                    </v-list-item-title>
                  </template>
                  <span>【{{ item.request.submit_login.end_user.end_company_name }}】【#{{ item.request.id }}】{{ item.request.subject }}</span>
                </v-tooltip>
                <v-list-item-action>
                    <span><v-chip x-small outlined>{{ item.create_at | moment("from") }}</v-chip></span>
                </v-list-item-action>
            </v-list-item>
          </v-card-text>
          <v-card-actions class="pa-0" v-if="newComments.length > 10">
            <v-spacer />
            <v-btn rounded small color="white" elevation="1" @click="isCommentsMore = !isCommentsMore" class="more">
              <v-icon small v-show="!isCommentsMore">mdi-arrow-collapse-down</v-icon>
              <v-icon small v-show="isCommentsMore">mdi-arrow-collapse-up</v-icon>
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" id="myTask">
        <v-card class="with-counts" height="100%">
          <v-card-title>自分の確認待ちの依頼
            <v-btn v-if="reviewRequestsTotal" fab elevation="3" class="counts" color="white" :to="{ name: 'client:request_index', params: { fillter: 'review' } }">
              <span class="font-weight-bold text-h6 orange--text">{{ reviewRequestsTotal }}</span>
            </v-btn>
          </v-card-title>
          <!-- <v-card-subtitle>お客様のご確認が必要な依頼です</v-card-subtitle> -->
          <v-card-text>
                <v-alert v-show="!reviewRequests.length" type="info" dense text color="grey">確認待ちの依頼は現在ありません</v-alert>
                <v-tooltip top v-for="(item, i) in reviewRequests" :key="i">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item class="px-0" v-bind="attrs" v-on="on" :to="{ name: 'client:request_show', params: { id: item.id } }">
                      <v-list-item-title>
                        <v-chip small class="secondary">#{{ item.id }}</v-chip>
                        {{ item.subject }}</v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>公開希望日 {{ item.release_date }}</span>
                </v-tooltip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  name: 'Dashboard',
  components: { LoginAvatar },
  props: {
    user_plan: Object
  },
  data: () => ({
    reviewRequests: [],
    reviewRequestsTotal: 0,
    company_info: {},
    newComments: [],
    isCommentsMore: false
  }),
  created: function () {
    this.readDataFromAPI()
  },
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    }
  },
  methods: {
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request`, {
        params: {
          schedule: 'review'
        }
      })
        .then(res => {
          this.reviewRequests = res.data.data
          this.reviewRequestsTotal = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request_comment/recent`)
        .then(res => {
          // console.log(res.data)
          this.newComments = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>

  .point-remaind{
    // position: absolute;
    // right: 0;
    // top: 0;
    padding: 5px 1.2em;
    margin-bottom: 30px;
    z-index: 0;
    background-color: #fff;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-bottom: 15px;
    }
  }

</style>
